<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="table-heading"
      class="mb-4"
    />
    <v-skeleton-loader
      type="card"
      class="mb-8"
    />
    <v-skeleton-loader
      type="card"
      class="mb-8"
    />
    <v-skeleton-loader
      type="card"
      class="mb-8"
    />
  </div>

  <div
    v-else
    ref="top"
  >
    <div class="pa-0 px-1 w-full d-flex align-center justify-end">
      <v-btn
        v-if="!addMode"
        :ripple="false"
        fab
        small
        color="primary"
        @click="addHallMock"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </div>

    <v-tabs
      v-model="currentHallIdx"
      show-arrows
      background-color="transparent"
      class="mb-4 elevation-0"
    >
      <v-tab
        v-for="(hall, hallTabIdx) in halls"
        :key="`hall_tab_${hallTabIdx}`"
      >
        {{ hall.attributes.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentHallIdx"
      style="background: transparent"
      touchless
    >
      <v-tab-item
        v-for="(hall, hallIdx) in halls"
        :key="`hall_${hallIdx}`"
      >
        <form @submit.prevent="handleClickSubmit">
          <v-card class="mb-8">
            <v-card-title>基本情報</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model.trim="formTemp.name"
                    label="名前"
                    required="true"
                    :error="!isValid.name"
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model.number="formTemp.tableCount"
                    label="卓数"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    min="1"
                    max="100"
                    required="true"
                    :error="!isValid.tableCount"
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model.number="formTemp.position"
                    label="順番"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    min="1"
                    max="100"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    :value="`${formTemp.tablePrefix || ''}1${formTemp.tableSuffix || ''}`"
                    label="卓表示名プレビュー"
                    readonly
                    outlined
                  />
                </v-col>

                <v-col
                  md="4"
                  cols="6"
                >
                  <v-text-field
                    v-model.trim="formTemp.tablePrefix"
                    label="卓名 接頭辞"
                  />
                </v-col>

                <v-col
                  md="4"
                  cols="6"
                >
                  <v-text-field
                    v-model.trim="formTemp.tableSuffix"
                    label="卓名 接尾辞"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="formTemp.tableInitConfigAttributes">
            <v-card class="mb-8">
              <v-card-title>卓作成設定</v-card-title>
              <v-card-text>
                <yona-has-many-v-select
                  v-model="formTemp.tableInitConfigAttributes.tableInitReferralsAttributes"
                  parent-name="tableInitConfig"
                  :parent-id="formTemp.tableInitConfigAttributes.id"
                  :children="referrals"
                  child-name="referral"
                  :label="currentClub.referralAlias"
                />

                <yona-has-many-v-select
                  v-model="formTemp.tableInitConfigAttributes.tableInitNominationsAttributes"
                  parent-name="tableInitConfig"
                  :parent-id="formTemp.tableInitConfigAttributes.id"
                  :children="nominations"
                  child-name="nomination"
                  :label="currentClub.nominationAlias"
                />

                <yona-has-many-v-select
                  v-model="formTemp.tableInitConfigAttributes.tableInitCoursesAttributes"
                  parent-name="tableInitConfig"
                  :parent-id="formTemp.tableInitConfigAttributes.id"
                  :children="courses"
                  child-name="course"
                  :label="currentClub.courseAlias"
                />

                <yona-has-many-v-select
                  v-model="formTemp.tableInitConfigAttributes.tableInitItemsAttributes"
                  parent-name="tableInitConfig"
                  :parent-id="formTemp.tableInitConfigAttributes.id"
                  :children="items"
                  child-name="item"
                  label="チャージ"
                />
              </v-card-text>
            </v-card>
          </template>

          <template v-if="formTemp.checkoutConfigAttributes">
            <v-card class="mb-8">
              <v-card-title>会計設定</v-card-title>

              <v-card-text>
                <v-card
                  elevation="0"
                  class="mb-8 pa-4"
                  outlined
                >
                  <v-card-title class="text-base pa-0 mb-2">
                    TAX
                    <v-spacer />
                    <v-btn
                      fab
                      icon
                      :ripple="false"
                      :color="isPreview.tax ? 'primary' : null"
                      @click="isPreview.tax = !isPreview.tax"
                    >
                      <v-icon>
                        {{ icons.mdiCalculatorVariantOutline }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-row class="pl-1">
                      <v-col
                        md="2"
                        cols="6"
                      >
                        <v-text-field
                          :value="`${(formTemp.checkoutConfigAttributes.serviceChargeRate * 100).toFixed()}`"
                          label="率"
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          step="1"
                          min="0"
                          suffix="%"
                          @input="formTemp.checkoutConfigAttributes.serviceChargeRate = ($event / 100).toFixed(2)"
                        />
                      </v-col>

                      <v-col
                        md="2"
                        cols="6"
                      >
                        <v-select
                          v-model="formTemp.checkoutConfigAttributes.taxMode"
                          :items="taxModes"
                          item-text="locale"
                          item-value="value"
                          label="消費税"
                          mandatory
                          :menu-props="{ auto: true, offsetY: true }"
                          no-data-text="選択可能なデータがありません"
                        />
                      </v-col>

                      <v-col
                        md="2"
                        cols="12"
                      >
                        <v-select
                          v-model="formTemp.checkoutConfigAttributes.taxRoundingMethod"
                          :items="roundingMethods"
                          item-text="locale"
                          item-value="value"
                          label="丸め処理"
                          mandatory
                          :menu-props="{ auto: true, offsetY: true }"
                          no-data-text="選択可能なデータがありません"
                        />
                      </v-col>

                      <v-col
                        v-if="formTemp.checkoutConfigAttributes.taxRoundingMethod !== 'ceil_to_multiple'"
                        md="6"
                        cols="12"
                      >
                        <v-slider
                          :value="Math.abs(formTemp.checkoutConfigAttributes.taxRoundingDigit)"
                          :tick-labels="['0', '一', '十', '百', '千', '万']"
                          :min="0"
                          :max="5"
                          label="丸める位"
                          step="1"
                          ticks="always"
                          tick-size="4"
                          @input="formTemp.checkoutConfigAttributes.taxRoundingDigit = ($event * -1)"
                        />
                      </v-col>
                      <v-col
                        v-else
                        md="2"
                        cols="12"
                      >
                        <v-text-field
                          v-model.number="formTemp.checkoutConfigAttributes.taxRoundingMultiple"
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                          label="倍数"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-expand-transition>
                    <div v-show="isPreview.tax">
                      <v-divider class="mt-4" />
                      <v-card-subtitle class="px-0">
                        プレビュー
                      </v-card-subtitle>
                      <v-row>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            :value="calcPreviewResult.tax.toLocaleString()"
                            label="算出されたTAX"
                            outlined
                            prefix="¥"
                            readonly
                            :loading="isCalculating.tax"
                          />
                        </v-col>

                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model.number="calcPreviewInput.tax"
                            label="金額"
                            type="number"
                            inputmode="numeric"
                            prefix="¥"
                            pattern="[0-9]*"
                          >
                            <template #append-outer>
                              <v-btn
                                :ripple="false"
                                small
                                color="primary"
                                :disabled="!isValidToCalcPreview.tax || isCalculating.tax"
                                :loading="isCalculating.tax"
                                @click="calcTax"
                              >
                                計算
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card>

                <v-card
                  elevation="0"
                  class="mb-8 pa-4"
                  outlined
                >
                  <v-card-title class="text-base pa-0 mb-2">
                    合計金額

                    <v-spacer />
                    <v-btn
                      :ripple="false"
                      fab
                      icon
                      :color="isPreview.total ? 'primary' : null"
                      @click="isPreview.total = !isPreview.total"
                    >
                      <v-icon>
                        {{ icons.mdiCalculatorVariantOutline }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-row>
                      <v-col
                        md="2"
                        cols="12"
                      >
                        <v-select
                          v-model="formTemp.checkoutConfigAttributes.totalRoundingMethod"
                          :items="roundingMethods"
                          item-text="locale"
                          item-value="value"
                          label="丸め処理"
                          mandatory
                          :menu-props="{ auto: true, offsetY: true }"
                          no-data-text="選択可能なデータがありません"
                        />
                      </v-col>

                      <template v-if="formTemp.checkoutConfigAttributes.totalRoundingMethod !== 'ceil_to_multiple'">
                        <v-spacer />
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-slider
                            :value="Math.abs(formTemp.checkoutConfigAttributes.totalRoundingDigit)"
                            :tick-labels="['0', '一', '十', '百', '千', '万']"
                            :min="0"
                            :max="5"
                            label="丸める位"
                            step="1"
                            ticks="always"
                            tick-size="4"
                            @input="formTemp.checkoutConfigAttributes.totalRoundingDigit = ($event * -1)"
                          />
                        </v-col>
                      </template>
                      <v-col
                        v-else
                        md="2"
                        cols="12"
                      >
                        <v-text-field
                          v-model.number="formTemp.checkoutConfigAttributes.totalRoundingMultiple"
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                          label="倍数"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-expand-transition>
                    <div v-show="isPreview.total">
                      <v-divider class="mt-4" />
                      <v-card-subtitle class="px-0">
                        プレビュー
                      </v-card-subtitle>
                      <v-row>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            :value="calcPreviewResult.total.toLocaleString()"
                            label="算出された合計金額"
                            outlined
                            prefix="¥"
                            readonly
                            :loading="isCalculating.total"
                          />
                        </v-col>

                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model.number="calcPreviewInput.total"
                            label="金額"
                            type="number"
                            inputmode="numeric"
                            prefix="¥"
                            pattern="[0-9]*"
                          >
                            <template #append-outer>
                              <v-btn
                                small
                                color="primary"
                                :ripple="false"
                                :disabled="!isValidToCalcPreview.total|| isCalculating.total"
                                :loading="isCalculating.total"
                                @click="calcTotal"
                              >
                                計算
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card>

                <v-card
                  elevation="0"
                  class="pa-4"
                  outlined
                >
                  <v-card-title class="text-base pa-0 mb-2">
                    会計手数料

                    <v-spacer />
                    <v-btn
                      fab
                      icon
                      :ripple="false"
                      :color="isPreview.processingFee ? 'primary' : null"
                      @click="isPreview.processingFee = !isPreview.processingFee"
                    >
                      <v-icon>
                        {{ icons.mdiCalculatorVariantOutline }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-row class="pl-1">
                      <v-col
                        md="2"
                        cols="6"
                      >
                        <v-text-field
                          :value="`${(formTemp.checkoutConfigAttributes.processingFeeRate * 100).toFixed()}`"
                          label="率"
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          step="1"
                          min="0"
                          suffix="%"
                          @input="formTemp.checkoutConfigAttributes.processingFeeRate = ($event / 100).toFixed(2)"
                        />
                      </v-col>

                      <v-col
                        md="2"
                        cols="6"
                      >
                        <v-select
                          v-model="formTemp.checkoutConfigAttributes.processingFeeTaxMode"
                          :items="taxModes"
                          item-text="locale"
                          item-value="value"
                          label="消費税"
                          mandatory
                          :menu-props="{ auto: true, offsetY: true }"
                          no-data-text="選択可能なデータがありません"
                        />
                      </v-col>

                      <v-col
                        md="2"
                        cols="12"
                      >
                        <v-select
                          v-model="formTemp.checkoutConfigAttributes.processingFeeRoundingMethod"
                          :items="roundingMethods"
                          item-text="locale"
                          item-value="value"
                          label="丸め処理"
                          mandatory
                          :menu-props="{ auto: true, offsetY: true }"
                          no-data-text="選択可能なデータがありません"
                        />
                      </v-col>

                      <v-col
                        v-if="formTemp.checkoutConfigAttributes.processingFeeRoundingMethod !== 'ceil_to_multiple'"
                        md="6"
                        cols="12"
                      >
                        <v-slider
                          :value="Math.abs(formTemp.checkoutConfigAttributes.processingFeeRoundingDigit)"
                          :tick-labels="['0', '一', '十', '百', '千', '万']"
                          :min="0"
                          :max="5"
                          label="丸める位"
                          step="1"
                          ticks="always"
                          tick-size="4"
                          @input="formTemp.checkoutConfigAttributes.processingFeeRoundingDigit = ($event * -1)"
                        />
                      </v-col>
                      <v-col
                        v-else
                        md="2"
                        cols="12"
                      >
                        <v-text-field
                          v-model.number="formTemp.checkoutConfigAttributes.processingFeeRoundingMultiple"
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                          label="倍数"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-expand-transition>
                    <div v-show="isPreview.processingFee">
                      <v-divider class="mt-4" />
                      <v-card-subtitle class="px-0">
                        プレビュー
                      </v-card-subtitle>
                      <v-row>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            :value="calcPreviewResult.processingFee.toLocaleString()"
                            label="算出された会計手数料"
                            outlined
                            prefix="¥"
                            readonly
                            :loading="isCalculating.processingFee"
                          />
                        </v-col>

                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model.number="calcPreviewInput.processingFee"
                            label="金額"
                            type="number"
                            inputmode="numeric"
                            prefix="¥"
                            pattern="[0-9]*"
                          >
                            <template #append-outer>
                              <v-btn
                                small
                                color="primary"
                                :ripple="false"
                                :disabled="!isValidToCalcPreview.processingFee|| isCalculating.processingFee"
                                :loading="isCalculating.processingFee"
                                @click="calcProcessingFee"
                              >
                                計算
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-card-text>
            </v-card>
          </template>

          <v-row class="d-flex align-center justify-end">
            <v-col
              md="3"
              cols="6"
            >
              <v-btn
                color="primary"
                :ripple="false"
                :disabled="Object.values({ ...isValid, ...isValidToCalcPreview }).includes(false)"
                :loading="isSubmitting"
                type="submit"
                block
              >
                {{ addMode ? '作成' : '更新' }}
              </v-btn>
            </v-col>

            <v-col
              md="3"
              cols="6"
            >
              <v-btn
                v-if="!addMode && halls.length > 1"
                :ripple="false"
                color="error"
                block
                @click="isDeleting = true"
              >
                <v-icon left>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>削除
              </v-btn>

              <v-btn
                v-if="addMode"
                block
                text
                :ripple="false"
                @click="currentHallIdx = 0"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="isDeleting"
      width="500"
    >
      <v-card>
        <v-card-title>
          <div>削除の確認</div>
        </v-card-title>
        <v-card-text>
          <p>削除を実行してもよろしいですか</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            color="error"
            @click="deleteHall"
          >
            削除
          </v-btn>
          <v-btn
            :ripple="false"
            color="blue darken-1"
            text
            @click="isDeleting = false"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  ref,
  watch,
  computed,
  reactive,
} from '@vue/composition-api'
import { mdiTrashCanOutline, mdiPlus, mdiCalculatorVariantOutline } from '@mdi/js'
import {
  sortBy,
  groupBy,
  map,
  includes,
} from 'lodash'
import { getVuetify } from '@core/utils'
import HallApi from '@/api/waiter/Hall'
import ReferralApi from '@/api/waiter/Referral'
import CheckoutConfigApi from '@/api/waiter/CheckoutConfig'
import VendibleApi from '@/api/waiter/Vendible'
import YonaHasManyVSelect from '@/views/components/util/YonaHasManyVSelect.vue'

export default {
  components: {
    YonaHasManyVSelect,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const $vuetify = getVuetify()

    const top = ref()
    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const isDeleting = ref(false)
    const currentHallIdx = ref()
    const halls = ref([])
    const formTemp = ref({
      name: '',
      tableCount: 12,
      tablePrefix: '',
      tableSuffix: '卓',
      position: halls.value.length,
      tableInitConfigAttributes: {
        tableInitReferralsAttributes: [],
        tableInitNominationsAttributes: [],
        tableInitCoursesAttributes: [],
        tableInitItemsAttributes: [],
      },
      checkoutConfigAttributes: {
        serviceChargeRate: 0,
        taxMode: 'normal',
        taxRoundingMethod: 'round',
        taxRoundingDigit: 0,
        totalRoundingMethod: 'round',
        totalRoundingDigit: 0,
        processingFeeRate: 0,
        processingFeeTaxMode: 'normal',
        processingFeeRoundingMethod: 'round',
        processingFeeRoundingDigit: 0,
        processingFeeRoundingMultiple: 0,
        taxRoundingMultiple: 0,
        totalRoundingMultiple: 0,
      },
    })
    const addMode = ref(false)
    const referrals = ref([])
    const nominations = ref([])
    const courses = ref([])
    const items = ref([])
    const taxModes = ref([])
    const roundingMethods = ref([])

    const isPreview = reactive({
      tax: false,
      total: false,
      processingFee: false,
    })
    const calcPreviewInput = reactive({
      tax: 0,
      total: 0,
      processingFee: 0,
    })
    const calcPreviewResult = reactive({
      tax: 0,
      total: 0,
      processingFee: 0,
    })
    const isCalculating = reactive({
      tax: false,
      total: false,
      processingFee: false,
    })

    const isValid = computed(() => {
      const { name, tableCount } = formTemp.value

      return {
        name: !!name,
        tableCount: !!tableCount && (tableCount > 0 && tableCount <= 100),
      }
    })

    const isValidToCalcPreview = computed(() => {
      const isValidTaxMode = str => includes(map(taxModes.value, 'value'), str)
      const isValidRoundingMethod = str => includes(map(roundingMethods.value, 'value'), str)

      const {
        taxMode,
        taxRoundingMethod,
        totalRoundingMethod,
        processingFeeTaxMode,
        processingFeeRoundingMethod,
      } = formTemp.value.checkoutConfigAttributes

      return {
        tax: isValidTaxMode(taxMode) && isValidRoundingMethod(taxRoundingMethod),
        total: isValidRoundingMethod(totalRoundingMethod),
        processingFee: isValidTaxMode(processingFeeTaxMode) && isValidRoundingMethod(processingFeeRoundingMethod),
      }
    })

    const getHalls = async () => {
      isLoading.value = true
      const res = await HallApi.getHalls({ isOperation: true, isSettings: true })

      if (res?.data) {
        halls.value = [...sortBy(res.data.halls.data, 'attributes.position')]
      }

      isLoading.value = false
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.data) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.data) {
        const vendibles = [...res.data.vendibles.data]

        const vendiblesByType = groupBy(vendibles, 'attributes.vendibleType')
        courses.value = vendiblesByType.Course
        nominations.value = vendiblesByType.Nomination
        items.value = vendiblesByType.Item
      }
    }

    const getCheckoutConfigEnums = async () => {
      const res = await CheckoutConfigApi.getEnums()

      if (res?.data) {
        const buildVSelectItems = enums => {
          return map(enums, (value, key) => {
            return {
              value: key,
              locale: value,
            }
          })
        }

        taxModes.value = buildVSelectItems({ ...res.data.taxModes })
        roundingMethods.value = buildVSelectItems({ ...res.data.roundingMethods })
      }
    }

    const deleteHall = async () => {
      isDeleting.value = false

      if (halls.value.length <= 1) {
        vm.$toast.error('ホールを削除に失敗しました')

        return
      }

      const res = await HallApi.deleteHall({
        hallId: halls.value[currentHallIdx.value].id,
      })

      if (res.data.status === 'error') {
        vm.$toast.error('削除できません')
        vm.$toast.error(res.data.message.join('\n'))

        return
      }

      if (res?.status === 200) {
        halls.value.splice(currentHallIdx.value, 1)
        $vuetify.goTo(top.value)
        vm.$toast.success('削除しました')
      }
    }

    const calcTax = async () => {
      isCalculating.tax = true

      const {
        serviceChargeRate,
        taxMode,
        taxRoundingMethod,
        taxRoundingDigit,
        taxRoundingMultiple,
      } = formTemp.value.checkoutConfigAttributes

      const res = await CheckoutConfigApi.calcTax({
        price: calcPreviewInput.tax,
        serviceChargeRate,
        taxMode,
        taxRoundingMethod,
        taxRoundingDigit,
        taxRoundingMultiple,
      })

      if (res?.data) calcPreviewResult.tax = res.data
      isCalculating.tax = false
    }

    const calcTotal = async () => {
      isCalculating.total = true

      const {
        totalRoundingMethod,
        totalRoundingDigit,
        totalRoundingMultiple,
      } = formTemp.value.checkoutConfigAttributes

      const res = await CheckoutConfigApi.calcTotal({
        price: calcPreviewInput.total,
        totalRoundingMethod,
        totalRoundingDigit,
        totalRoundingMultiple,
      })

      if (res?.data) calcPreviewResult.total = res.data
      isCalculating.total = false
    }

    const calcProcessingFee = async () => {
      isCalculating.processingFee = true

      const {
        processingFeeRate,
        processingFeeTaxMode,
        processingFeeRoundingMethod,
        processingFeeRoundingDigit,
        processingFeeRoundingMultiple,
      } = formTemp.value.checkoutConfigAttributes

      const res = await CheckoutConfigApi.calcProcessingFee({
        price: calcPreviewInput.processingFee,
        processingFeeRate,
        processingFeeTaxMode,
        processingFeeRoundingMethod,
        processingFeeRoundingDigit,
        processingFeeRoundingMultiple,
      })

      if (res?.data) calcPreviewResult.processingFee = res.data
      isCalculating.processingFee = false
    }

    const addHallMock = () => {
      addMode.value = true
      halls.value.push({
        attributes: {
          name: 'NEW',
          tableCount: 12,
          tablePrefix: '',
          tableSuffix: '卓',
          checkoutConfig: {
            data: {
              id: '',
              type: 'checkoutConfig',
              attributes: {
                serviceChargeRate: 0,
                taxMode: 'normal',
                taxRoundingMethod: 'round',
                taxRoundingDigit: 0,
                totalRoundingMethod: 'round',
                totalRoundingDigit: 0,
                processingFeeRate: 0,
                processingFeeTaxMode: 'normal',
                processingFeeRoundingMethod: 'round',
                processingFeeRoundingDigit: 0,

              },
            },
          },
          tableInitConfig: {
            data: {
              id: '',
              type: 'tableInitConfig',
              attributes: {
                tableInitReferrals: {
                  data: [],
                },
                tableInitNominations: {
                  data: [],
                },
                tableInitCourses: {
                  data: [],
                },
                tableInitItems: {
                  data: [],
                },
              },
            },
          },
        },
      })

      currentHallIdx.value = halls.value.length - 1
    }

    const buildFormTemp = hall => {
      const {
        name,
        tableCount,
        tablePrefix,
        tableSuffix,
        position,
      } = hall.attributes

      const checkoutConfig = hall.attributes.checkoutConfig.data
      const tableInitConfig = hall.attributes.tableInitConfig.data

      return {
        name,
        tableCount,
        tablePrefix,
        tableSuffix,
        position,
        checkoutConfigAttributes: {
          id: checkoutConfig.id,
          ...checkoutConfig.attributes,
        },
        tableInitConfigAttributes: {
          id: tableInitConfig.id,
          tableInitReferralsAttributes: map(tableInitConfig.attributes.tableInitReferrals.data, o => {
            return {
              id: o.id,
              ...o.attributes,
              _destroy: false,
            }
          }),
          tableInitNominationsAttributes: map(tableInitConfig.attributes.tableInitNominations.data, o => {
            return {
              id: o.id,
              ...o.attributes,
              _destroy: false,
            }
          }),
          tableInitCoursesAttributes: map(tableInitConfig.attributes.tableInitCourses.data, o => {
            return {
              id: o.id,
              ...o.attributes,
              _destroy: false,
            }
          }),
          tableInitItemsAttributes: map(tableInitConfig.attributes.tableInitItems.data, o => {
            return {
              id: o.id,
              ...o.attributes,
              _destroy: false,
            }
          }),
        },
      }
    }

    const handleClickSubmit = async () => {
      isSubmitting.value = true

      if (addMode.value) {
        // NOTE: create hall
        const res = await HallApi.createHall({
          params: formTemp.value,
        })

        if (res.data) {
          const hall = res.data.hall.data
          halls.value.splice(currentHallIdx.value, 1, hall)
          formTemp.value = buildFormTemp(hall)
          addMode.value = false
          vm.$toast.success('ホールを作成しました')
        }
      } else {
        // NOTE: update hall
        const res = await HallApi.updateHall({
          hallId: halls.value[currentHallIdx.value].id,
          params: formTemp.value,
        })

        if (res.data) {
          const hall = res.data.hall.data
          halls.value.splice(currentHallIdx.value, 1, hall)
          formTemp.value = buildFormTemp(hall)
          vm.$toast.success('ホールを更新しました')
        }
      }

      isSubmitting.value = false
      $vuetify.goTo(top.value)
    }

    watch(currentHallIdx, (newIdx, _prevIdx) => {
      if (addMode.value && newIdx !== halls.value.length - 1) {
        halls.value.pop()
        addMode.value = false
      }

      formTemp.value = buildFormTemp(halls.value[currentHallIdx.value])

      isPreview.tax = false
      isPreview.total = false
      isPreview.processingFee = false
      calcPreviewInput.tax = 0
      calcPreviewInput.total = 0
      calcPreviewInput.processingFee = 0
      calcPreviewResult.tax = 0
      calcPreviewResult.total = 0
      calcPreviewResult.processingFee = 0
    })

    getHalls()
    getReferrals()
    getVendibles()
    getCheckoutConfigEnums()

    return {
      // data
      top,
      isLoading,
      isSubmitting,
      currentHallIdx,
      halls,
      addMode,
      formTemp,
      isDeleting,
      referrals,
      nominations,
      courses,
      items,
      taxModes,
      roundingMethods,
      isPreview,
      calcPreviewInput,
      calcPreviewResult,
      isCalculating,

      // computed
      isValid,
      isValidToCalcPreview,

      // methods
      handleClickSubmit,
      addHallMock,
      deleteHall,
      calcTax,
      calcTotal,
      calcProcessingFee,

      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiCalculatorVariantOutline,
      },
    }
  },
}
</script>
